<template>
  <div class="card">

    <div class="bg-blue-light p-1">
      <TitleButton
        :showBtn="true"
        :showAddNew="false"
        title="Hospital Bill"
        @onClickCloseButton="goToList"
      />
    </div>

    <div class="my-2 px-2">
      <TableHeaderHospitalBillAdd
        :formData="formData"
        :tableItems="tableItems"
      />
    </div>

    <div class="col-12 px-2">
      <ListTable
        :tableItems="tableItems"
        @addNewItem="addNewItem"
        :vatRate="vatRate"
        :salesAccountHeads="salesAccountHeads"
        :is-hide-add-btn="true"
      />
    </div>
    <div class="row px-2">
      <div class="col-md-6">
        <div class="mb-1">
          <label class="form-label" for="description">Ledger Memo</label>
          <vField
            as="textarea"
            name="description"
            type="number"
            class="form-control"
            v-model="formData.description"
          />
        </div>
      </div>
      <div class="col-md-5 ml-auto mt-auto mb-1">
        <div class="d-flex flex-wrap gap-1 gy-2">
          <button @click="handleSubmit()" class="btn btn-primary">Return Bill</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {inject, onMounted, provide, reactive, ref} from 'vue';
import TitleButton from '@/components/atom/TitleButton';
import ListTable from '@/components/molecule/company/hospital/billing/bill-manager/InvoiceHospitalTable.vue';
import {useRoute, useRouter} from 'vue-router';
import {generateUUID} from '@/services/utils/global';
import handlePurchase from '@/services/modules/purchase';
import {vatRate} from '@/data/inventory.js';
import handleCompany from "@/services/modules/company";
import hospitalPdfPrinterHelper from '@/services/utils/hospitalPdfPrinterHelper';
import TableHeaderHospitalBillAdd
  from '@/components/molecule/company/hospital/billing/bill-manager/TableHeaderHospitalBillAdd.vue';
import handleHospitalBilling from "@/services/modules/hospital/billing";
import useDate from "@/services/utils/day";
import salesReturnHelper from "@/services/utils/pdf/salesReturnHelper";

const {generatePdf} = hospitalPdfPrinterHelper();
const {fetchProductList, fetchSalesBill, ...rest} = handlePurchase();
const {fetchCompanyInfo} = handleCompany();
const {returnBillings} = handleHospitalBilling();
const { fetchAndGeneratePdf } = salesReturnHelper();

const $route = useRoute();
const $router = useRouter();
const dateHelper = useDate();

const companyId = $route.params.companyId;
const loader = ref(true);
const salesAccountHeads = ref([]);
const totals = ref({});
const invoiceRes = ref({});
const company = ref({});

let formData = ref({
  company_id: companyId,
  contact_profile_id: null,
  account_head_id: null,
  ipd_register_id: null,
  vat_payable_account_head_id: null,
  business_id: null,
  mop_account_head_id: null,
  receipt_reference: null,
  receipt_description: "Hospital bill payment",
  payment: null,
  project_id: null,
  cost_centre_id: null,
  location_id: null,
  discount_head_id: null,
  adjustment_amount: 0,
  status: 'active',
  bill_number: '',
  money_receipt_no: '',
  date: '',
  has_item_detail: true,
  description: '',
  order_number: null,
  sales_person_id: null,
  commission_percent: null,
  lc_number: null,
  item_details: []
});

provide('formData', formData);
provide('totals', totals);
const showSuccess = inject('showSuccess');
const showError = inject('showError');

onMounted(async () => {
  const companyQuery = `?company_id=${companyId}`;
  const salesBillQuery = `${companyQuery}&return_data=1`
  const fetchCompany = fetchCompanyInfo(companyId)
  const salesAccountHeadsRes = rest.getAccountHeadBySlag("sales_accounts", companyQuery)
  const getSalesBill = fetchSalesBill($route.params.invoiceId, salesBillQuery)

  Promise.all([
    fetchCompany.then(res => {
      if (res.data) company.value = res.data;
    }),
    salesAccountHeadsRes.then(res => {
      if (res.data) salesAccountHeads.value = res.data
    }),
    getSalesBill.then(res => {
      if (res.data) {
        const {id, date, account_head, company, contact_profile, discount_ledger, general, receipt_generals, stocks} = res.data;
        formData.value = res.data;
        formData.value.sale_master_id = id;
        formData.value.account_payable_head_id = res.data.account_head_id;
        formData.value.invoice_date = date;
        formData.value.date = dateHelper.currentDate();
        formData.value.adjustment_amount = res.data.adjustment_amount ?? 0;
        const items = general.map(td => {
          return {
            id: td.id,
            product_id: td.product_id,
            name: td.product.name,
            option_name: td.product.name,
            description: td.description,
            quantity: td.quantity,
            rate: td.rate,
            discount: td.discount ?? 0,
            vat: td.vat,
            sub_total: td.sub_total,
            sale_general_id: td.id,
            discount_amount: td.discount_amount ?? 0,
            discount_percent: td.discount_percent,
            service_resource_id: td.service_resource_id,
            service_resource_name: td.service_resource ? td.service_resource.full_name : null,
            service_resource: td.service_resource ? {
              id: td.service_resource.id,
              name: td.service_resource.full_name
            } : undefined,
            resource_description_id: td.service_resource_description_id,
            service_resource_price: td.service_resource_price,
            vat_amount: td.vat_amount,
            total: td.total,
            isEdit: false,
            product: {name: td.product.name},
            amount: td.amount,
            total_amount: td.total_amount
          }
        });
        tableItems.push(...items)

        if (receipt_generals && receipt_generals[0]) {
          formData.value.money_receipt_no = receipt_generals[0].receipt_master.voucher_no
          formData.value.mop_account_head_id = receipt_generals[0].receipt_master.account_head_id
          formData.value.receipt_master_id = receipt_generals[0].receipt_master.id;
          formData.value.receipt_reference = receipt_generals[0].receipt_master.receipt_reference
          formData.value.total_return_amount = res.data.paid_amount
        }
        if (discount_ledger) {
          formData.value.discount_head_id = discount_ledger.account_head_id;
          formData.value.adjustment_amount = discount_ledger.debit;
        }
      }
    })
  ])
    .then(() => {
      loader.value = false
    })
    .catch(() => {
      loader.value = false
    })
})

let tableItems = reactive([]);

const addNewItem = () => {

  tableItems.push({
    id: generateUUID(),
    product_id: null,
    name: '',
    description: '',
    quantity: 1,
    rate: 0,
    discount_percent: 0,
    vat: 15,
    sub_total: 0,
    discount_amount: 0,
    vat_amount: 0,
    total: 0,
    isEdit: false,
  });

}

const goToList = () => {
  const {redirect_route, ...routeQuery} = $route.query
  const routeName = redirect_route ? redirect_route : "bill-manager"
  $router.push({
    name: routeName,
    params: $route.params,
    query: {
        ...routeQuery
    }
  })
}

const handleSubmit = () => {
  loader.value = true

  formData.value.item_details = tableItems;
  let copyFormData = JSON.parse(JSON.stringify(formData.value))
  copyFormData.item_details = formData.value.item_details
  copyFormData.sale_type = $route.query.sale_type ?? "hospital_invoice"
  copyFormData.receipt_type = "hospital_invoice_return"

  returnBillings(copyFormData).then(res => {
    if (!res.status) {
      return showError(res.message)
    }
    showSuccess(res.message)
    fetchAndGeneratePdf(company.value, res.data.id, 'hospital_invoice')
    goToList();
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loader.value = false
  })
}

</script>
<style scoped>
address {
  margin-bottom: 0 !important;
}
</style>